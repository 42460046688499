<template>
    <div v-if="visible" class="offline-notification">
        <p>You are currently offline. Some features may be limited.</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
    },
    mounted() {
        this.updateVisibility();
        window.addEventListener('online', this.updateVisibility);
        window.addEventListener('offline', this.updateVisibility);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateVisibility);
        window.removeEventListener('offline', this.updateVisibility);
    },
    methods: {
        updateVisibility() {
            this.visible = !navigator.onLine; // Show if offline
        },
    },
};
</script>

<style scoped>
.offline-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #F4EBFF;
    padding: 10px;
    border: 1px solid #A559FF;
    border-radius: 5px;
    z-index: 1000;
}
</style>