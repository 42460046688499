<template>
    <div v-if="visible" class="refresh-notification">
        <p>New content is available!</p>
        <button @click="reloadApp">Reload</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        reloadApp() {
            window.location.reload();
        },
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.refresh-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #F4EBFF;
    padding: 10px;
    border: 1px solid #A559FF;
    border-radius: 5px;
    z-index: 1000;
}
</style>